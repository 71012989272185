<template>
  <div class="transactions-wrapper">
    <div class="centered-loading-wrapper" v-if="loading">
      <LoadingSpinner />
    </div>
    <Card v-else :flex="12" title="Transactions">
      <template v-slot:content>
        <section class="service-transaction-log">
          <div class="control-wrapper">
            <TransactionLog
              minHeight="300px"
              :rowdataProp="transactionsData?.transactions" />
          </div></section
      ></template>
    </Card>
  </div>
</template>

<script>
  import { LocalDate, DateTimeFormatter } from "@js-joda/core";
  import { mapGetters } from "vuex";

  // components
  import TransactionLog from "@/components/TransactionLog.vue";
  import Card from "@/components/Card.vue";
  import LoadingSpinner from "@/components/LoadingSpinner";

  // helpers
  import { getTransactionEndpoint } from "@/helpers/transactions";

  import { TransactionGraph } from "@tucktrucks/platform-base-private";

  export default {
    components: {
      TransactionLog,
      Card,
      LoadingSpinner,
    },

    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        loading: true,
        transactionsData: null,
      };
    },

    props: {
      pageData: {
        type: Object,
      },
      pageDataType: {
        type: String,
      },
    },

    computed: {
      ...mapGetters({
        apiPrivateMenusGetByVendorId: "apiPrivateMenus/getByVendorId",
        apiPrivateServicesGetById: "apiPrivateServices/getById",
      }),

      currentDate() {
        if (!this.pageData) return "";
        const date = this.pageData.startDate || this.pageData.date;

        return LocalDate.parse(date).format(
          DateTimeFormatter.ofPattern("yyyy-MM-dd")
        );
      },
    },

    methods: {
      getTransactionsData() {
        var request = {};

        if (this.pageDataType == "service") {
          request.services = [this.pageData?.id];
        }

        if (this.pageDataType == "event") {
          request.events = [this.pageData?.id];
        }

        getTransactionEndpoint(this.operatorType, this.operatorId, request)
          .then((response) => {
            if (response.status == 200) {
              TransactionGraph.create(response.data.data).then((graph) => {
                this.transactionsData = graph;
              });

              return;
            }

            return Promise.reject({
              operator: { type: this.operatorType, id: this.operatorId },
              request,
              response,
            });
          })
          .catch((ex) => {
            window.log.error("Could not download transactions.", ex);
          })
          .finally(() => (this.loading = false));
      },
    },

    async beforeMount() {
      this.getTransactionsData();
    },
  };
</script>

<style lang="scss">
  .transactions-wrapper {
    width: 100%;
  }
  .service-transaction-log {
    width: 100%;
    .ag-theme-alpine {
      height: 394px !important;
    }
  }
  .control-wrapper {
    position: relative;
    height: 394px;
    width: 100%;

    .chart {
      height: 200px;
      width: 100%;
      overflow: visible;
    }
  }

  // @media screen and (max-width: 1440px) {
  //   .control-wrapper {
  //     height: initial !important;
  //   }
  // }
</style>
