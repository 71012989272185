<template>
  <li class="data-item">
    <span class="box" :style="{ background: backgroundColor }"> </span>
    <span class="column no-gap">
      <span class="label" v-if="label != null"> {{ formatLabel }}</span>
      <span class="value">
        {{ formatValue }}
      </span>
    </span>
  </li>
</template>

<script>
  import { upperCamelCaseToString } from "@/helpers/stringFormatter.js";

  export default {
    props: {
      label: {
        type: String,
      },
      value: {
        type: Number,
      },
      moneyType: {
        type: Boolean,
      },
      backgroundColor: {
        type: String,
      },
    },
    computed: {
      formatValue() {
        return this.moneyType ? this.formatMoney(this.value) : this.value;
      },
      formatLabel() {
        return upperCamelCaseToString(this.label);
      },
    },
  };
</script>

<style lang="scss">
  .data-item {
    font-size: 0.7rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    .box {
      width: 0.5rem;
      height: 0.5rem;
      margin-right: 5px;
      margin-bottom: 5px;
      display: block;
      border-radius: 1px;
      flex-shrink: 0;
    }
    .label {
      margin-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .value {
      color: #999;
    }
  }
</style>
