<template>
  <div class="detail service-detail">
    <span class="detail-label">{{ label }}: </span>
    <span
      v-if="!this.editable && !this.useSlot"
      class="detail-control input-fixed">
      {{ value }}
    </span>
    <div class="detail-control__wrapper" v-else-if="this.editable">
      <input
        class="detail-control"
        :class="{ invalid: error }"
        required
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @change="$emit('change')"
        :type="type"
        :name="name"
        :id="id"
        :max="max" />
      <p class="error" v-if="error">{{ error }}</p>
    </div>

    <slot v-else class="detail-control"> </slot>
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
      },
      value: {
        type: String,
      },
      editable: {
        type: Boolean,
        default: false,
      },
      useSlot: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: "time",
      },
      name: {
        type: String,
      },
      id: {
        type: String,
      },
      max: {
        type: String,
      },
      modelValue: {
        type: String,
      },
      error: {
        type: String,
      },
    },
    emits: ["update:modelValue", "change"],
  };
</script>

<style lang="scss">
  .service-detail {
    @include flex-initial($alignItems: baseline, $justifyContent: center);

    .detail-control {
      &__wrapper {
        @include flex-initial($direction: column);
        width: min-content;

        input.invalid {
          border-color: $col_alpha;
        }

        .error {
          font-size: 0.75rem;
          color: $col_alpha;
        }
      }
    }
  }
</style>
