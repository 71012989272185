<template>
  <div class="sales-summary-wrapper">
    <Card :flex="12" title="Sales Overview">
      <template v-slot:content>
        <section
          class="sales-summary"
          v-for="(chartDataType, index) in chartDataTypes"
          v-bind:key="index">
          <div class="control-wrapper">
            <div class="row mb_1 justify-space-between align-center">
              <h5 class="title">{{ getChartTitle(chartDataType) }}</h5>
              <ChartConfig label="Show:">
                <ChartLimitSelect
                  :data="initialChartData[chartDataType]"
                  :dataType="chartDataType"
                  :loading="chartIsLoading"
                  @change-limit="setChartLimit"
                  @optionClicked="toggleFilter(chartDataType)" />
              </ChartConfig>
            </div>
            <span class="summary-chart-total">
              {{
                this.formatMoney(
                  getChartToplineFigure(initialChartData[chartDataType])
                )
              }}</span
            >

            <div class="centered-loading-wrapper" v-if="loadingChartData">
              <LoadingSpinner />
            </div>
            <div
              class="column canvas-labels-wrapper"
              v-if="!loadingChartData && chartData[chartDataType]">
              <Chart
                :data="chartData[chartDataType]"
                :id="String(index)"
                :show-labels="chartDataType !== chartDataTypes[0]"
                type="doughnut"
                :aspectRatio="1"
                :legend="legendsConfigForDonutChart"
                @set-loading="setChartIsLoading" />

              <ul class="row chart-data-labels">
                <ChartLabelValueItem
                  class="data-item"
                  v-for="(label, index) in chartData[chartDataType].labels"
                  v-bind:key="index"
                  :label="label"
                  :value="chartData[chartDataType].datasets[0].data[index]"
                  :moneyType="true"
                  :backgroundColor="
                    chartData[chartDataType].datasets[0].backgroundColor[index]
                  " />
              </ul>
            </div>
            <NoDataWrap
              v-if="!loadingChartData && !this.chartData[chartDataType]"
              >No Data</NoDataWrap
            >
          </div>
        </section>
      </template>
    </Card>
  </div>
</template>

<script>
  // components
  import LoadingSpinner from "@/components/LoadingSpinner";
  import ChartLimitSelect from "@/components/ChartLimitSelect.vue";
  import Chart from "@/components/charts/Chart";
  import Card from "@/components/Card.vue";
  import NoDataWrap from "@/components/NoDataWrap.vue";
  import ChartLabelValueItem from "@/components/charts/ChartLabelValueItem.vue";
  import ChartConfig from "@/components/ChartConfig.vue";

  // helpers
  import { pieChartColours } from "@/helpers/chartColours";
  import Enumerable from "linq";
  import { getServiceSummaryEndpoint } from "@/helpers/salesOverview";

  // constants
  import {
    OPERATORTYPEVENUE,
    OPERATORTYPEVENDOR,
  } from "@/constants/operatorTypes.ts";

  // icons
  import { mdiTune } from "@mdi/js";

  export default {
    components: {
      LoadingSpinner,
      Chart,
      Card,
      NoDataWrap,
      ChartLabelValueItem,
      ChartLimitSelect,
      ChartConfig,
    },

    data() {
      return {
        chartData: { channels: null, items: null, components: null },
        initialChartData: {},
        chartDataTypes: ["channels", "items", "components"],
        loadingChartData: true,
        legendsConfigForDonutChart: {
          show: false,
        },
        chartIsLoading: false,
        mdiTune,
      };
    },

    computed: {
      isVendorLevel() {
        return this.$route.params.serviceId;
      },
    },

    props: {
      service: {
        type: Object,
      },
      eventDate: {
        type: String,
      },
    },

    methods: {
      toggleFilter(item) {
        let ref = item + "Filter";
        this.$refs[ref][0].classList.toggle("active");
      },

      getChartTitle(type) {
        if (type === "components") return "Alterations";
        return type;
      },

      getChartToplineFigure(data) {
        if (data?.datasets) {
          return Enumerable.from(data.datasets[0].data).sum();
        }

        return 0;
      },

      getChartColours(length) {
        return pieChartColours(length);
      },

      getSummary() {
        this.loadingChartData = true;

        const id = this.isVendorLevel
          ? this.$route.params.serviceId
          : this.$route.params.operatorId;
        const operatorType = this.isVendorLevel
          ? OPERATORTYPEVENDOR
          : OPERATORTYPEVENUE;

        getServiceSummaryEndpoint(operatorType, id, this.eventDate)
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }
            return Promise.reject("Could not download summary.");
          })
          .then((data) => {
            for (const type of this.chartDataTypes) {
              if (data[type].length < 1) return (this.chartData[type] = null);
              this.chartData[type] = {
                labels: data[type].map((item) => {
                  const amount =
                    type === "components"
                      ? item.amountAdded
                      : type == "channels"
                      ? item.orderCount
                      : item.amount;
                  const label = item.name;
                  return label + " (" + amount + ")";
                }),
                datasets: [
                  {
                    data: data[type].map((item) => {
                      return type === "items"
                        ? item.revenueBase
                        : item.revenueConfigured ?? item.revenue;
                    }),
                    backgroundColor: pieChartColours(data[type].length),
                    hoverOffset: 4,
                  },
                ],
              };
            }
          })
          .then(() => {
            this.initialChartData = JSON.parse(JSON.stringify(this.chartData));
          })
          .catch((err) => {
            window.log.error(err);
          })
          .finally(() => (this.loadingChartData = false));
      },

      setChartLimit(newData, dataType) {
        this.chartData[dataType] = newData;
      },

      setChartIsLoading(status) {
        this.chartIsLoading = status;
      },
    },

    async beforeMount() {
      this.getSummary();
    },
  };
</script>

<style lang="scss">
  .sales-summary-wrapper {
    width: 100%;
  }

  .salesoverview.card {
    .card__content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
      @media screen and (max-width: 900px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
      }
    }
    .canvas-labels-wrapper {
      overflow: visible;
    }

    section.sales-summary {
      height: 394px !important;
      //overflow: hidden;

      .centered-loading-wrapper,
      .no-data-wrap {
        height: calc(100% - 80px);
      }
      .control-wrapper {
        position: relative;
        height: 394px;
        width: 100%;

        .chart {
          height: 200px;
          width: 100%;
          overflow: visible;
        }
      }
    }
  }

  .control-wrapper {
    margin-bottom: 1rem;
    h5.title {
      font-size: 1rem;
      margin: 0;
      text-transform: capitalize;
    }
  }

  .summary-chart-total {
    font-weight: 60;
    font-size: 20px;
  }
</style>
