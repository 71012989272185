<template>
  <Icon class="filter-icon" :path="mdiTune" :size="24" @click="togglePopup()" />
  <div class="chart-config-inner" :class="{ active: this.showPopup }">
    <p class="chart-config__label">{{ label }}</p>
    <slot></slot>
  </div>
</template>

<script>
  import { mdiTune } from "@mdi/js";

  export default {
    data() {
      return {
        mdiTune,
        showPopup: false,
      };
    },
    props: {
      label: {
        type: String,
      },
    },
    methods: {
      togglePopup() {
        this.showPopup = !this.showPopup;
      },
    },
  };
</script>

<style lang="scss">
  .filter-icon {
    color: $col_faded;
  }

  .chart-config-inner {
    display: none;
    position: absolute;
    left: auto;
    right: 20px;
    top: 20px;
    padding: 0.8rem;
    background: white;
    border-radius: 6px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);

    &.active {
      display: block;
    }
  }

  .chart-config__label {
    color: $col_faded-darker;
    margin: 0 0 0.3rem;
    font-size: 0.8rem;
  }
</style>
