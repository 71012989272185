<template>
  <div class="single-select">
    <template v-for="item in options" :key="item.text">
      <input
        type="radio"
        :id="item.text + dataType"
        :name="dataType"
        :value="item.value"
        :checked="item.value === limit"
        :disabled="loading"
        v-model="limit" />
      <label
        @click="$emit('optionClicked')"
        :for="item.text + dataType"
        class="single-select__option"
        >{{ item.text }}</label
      >
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
      },
      dataType: {
        type: String,
      },
      loading: {
        type: Boolean,
      },
    },
    data() {
      return {
        options: [
          {
            value: 3,
            text: "Top 3",
          },
          {
            value: 5,
            text: "Top 5",
          },
          {
            value: null,
            text: "All Items",
          },
        ],
        limit: 5,
      };
    },
    watch: {
      limit() {
        this.limitChartData();
      },
      data() {
        this.limitChartData();
      },
    },
    methods: {
      limitChartData() {
        if (!this.data) return;

        const copyOfInitialData = JSON.parse(JSON.stringify(this.data));
        const limitValue = this.limit
          ? this.limit
          : this.data.datasets[0].data.length;

        // add initial index
        let serializedLabels = this.serializeChartData(this.data.labels);
        let serializedColors = this.serializeChartData(
          this.data.datasets[0].backgroundColor
        );
        const serializedData = this.serializeChartData(
          this.data.datasets[0].data
        );

        let sortedData = serializedData
          .sort((firstItem, secondItem) => secondItem.value - firstItem.value)
          .slice(0, limitValue);

        // remove initial index
        serializedLabels = this.filterChartData(serializedLabels, sortedData);
        serializedColors = this.filterChartData(serializedColors, sortedData);

        copyOfInitialData.labels = this.deserializeChartData(serializedLabels);
        copyOfInitialData.datasets[0].backgroundColor =
          this.deserializeChartData(serializedColors);
        copyOfInitialData.datasets[0].data =
          this.deserializeChartData(sortedData);

        this.$emit("changeLimit", copyOfInitialData, this.dataType);
      },

      serializeChartData(data) {
        return data.map((value, index) => {
          return {
            index,
            value,
          };
        });
      },

      deserializeChartData(data) {
        return data.map((item) => item.value);
      },

      filterChartData(data, sortedData) {
        return data
          .filter((item) =>
            sortedData.find((element) => {
              return element.index === item.index;
            })
          )
          .sort((a, b) => {
            return (
              sortedData.map((e) => e.index).indexOf(a.index) -
              sortedData.map((e) => e.index).indexOf(b.index)
            );
          });
      },
    },

    beforeMount() {
      this.limitChartData();
    },
  };
</script>

<style scoped lang="scss">
  .single-select {
    & input[type="radio"] {
      opacity: 0;
      position: fixed;
      width: 0;
    }
    display: flex;
    gap: 0.5rem;
    width: 100%;

    &__option {
      border: 1px solid #ddd;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 2px 10px;
      font-size: 0.75rem;
      white-space: nowrap;
    }

    input[type="radio"]:checked + label {
      background: $col_alpha;
      color: white;
      border-color: $col_alpha;
    }
  }
</style>
