import store from "@/store";

export function getServiceSummaryEndpoint(operatorType, id, eventDate) {
  switch (operatorType) {
    case "vendor":
      return store.state.apiPrivate.client.endpoints.serviceSummary.get(id);
    case "venue":
      return store.state.apiPrivate.client.endpoints.venueSummarySales.get(
        {
          dateFrom: eventDate,
          dateTo: eventDate,
        },
        id
      );
    default:
      break;
  }
}
